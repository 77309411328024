<template>
   <div>
      <Toast />
      
      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
                  <template v-slot:right>
                     <Dropdown v-model="selectedStatus" :options="columns" optionLabel="status" optionValue="field" placeholder="Select a Status" @change="onToggle" style="width: 20em"/>
                  </template>
               </Toolbar>
               <DataTable
                  :value="lists"
                  :paginator="true"
                  :rows="10"
                  :loading="loading"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 50]"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'customerDTO.name',
                     'productItemDTO.name',
                     'dealPrice',
                     'downPayment',
                     'estimateDeliveryDate',
                     'stockType',
                  ]"
                  responsiveLayout="scroll"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column field="id" header="ID"></Column>
                  <Column
                     field="customerDTO.name"
                     header="Customer Name"
                  ></Column>
                  <Column
                     field="productItemDTO.name"
                     header="Item Name"
                  ></Column>
                  <Column field="quantity" header="Quantity"></Column>
                  <Column field="dealPrice" header="Deal Price"></Column>
                  <Column field="downPayment" header="Downpayment"></Column>
                  <Column field="estimateDeliveryDate" header="Date"></Column>
                  <Column field="status" header="Status"></Column>
                  <Column field="stockType" header="Stock"></Column>
                  <Column header="Action" style="min-width:25rem">
                     <template #body="{ data }">
                        <Button
                           label="Details"
                           icon="pi pi-eye"
                           class="p-button-rounded p-button-info mr-2 button-group"
                           @click="getDetails(data.id)"
                           iconPos="right"
                        />
                        <Button
                           :disabled="data.status != 2"
                           label="Approve"
                           icon="pi pi-check-circle"
                           class="p-button-rounded p-button-success mr-2 button-group"
                           @click="openApproveDialog(data)"
                           iconPos="right"
                        />
                        <Button
                           :disabled="data.status != 2"
                           label="Reject"
                           icon="pi pi-times-circle"
                           class="p-button-rounded p-button-danger mr-2 button-group"
                           @click="openRejectDialog(data)"
                           iconPos="right"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";

export default {
   mixins: [helperMixins, requestMixins],
   data() {
      return {
         home: { icon: "pi pi-home", to: "/" },
         items: [{ label: "CCO Orders" }],
         columns: null,
         selectedStatus: null,
      };
   },
   created() {
      this.initFilters1();
      this.index();

      this.columns = [
            {field: 0, status: 'All'},
            {field: 1, status: 'New'},
            // {field: 2, status: 'Delivered'},
            {field: 3, status: 'Completed'},
            {field: 4, status: 'Canceled'},
            {field: 5, status: 'Approved'},
            {field: 6, status: 'Rejected'},
        ];
   },
   methods: {
      
      async index() {
         this.lists = (await this.getMethod("customer-orders/all")).data;

         this.loading = false;
      },
      getDetails(id) {
         this.$router.push({ name: "cco_orders_details", params: { id: id } });
      },
      async onToggle(){
         if(this.selectedStatus === 0){
            this.index()
         }else{
            this.lists = (await this.getMethod("customer-orders/all/"+ this.selectedStatus)).data;
         }
      },
      openApproveDialog(data) {

         const payLoad = {
            id : data.id,
            status : data.status
         }

         this.$confirm.require({
            message: 'Do you want to Approve this COO Order?',
            header: 'Approve Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
               this.updateMethod("customer-orders/approve", payLoad)
                  .then((data) => {
                     this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: data.message,
                        life: 3000,
                     });

                     this.index();
                  })
                  .catch((error) => {
                     this.loading = false;
                     this.$toast.add({
                        severity: "error",
                        summary: "Something Wrong",
                        detail: error.response.data.message,
                        life: 3000,
                     });
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Cancel Approve",
                  life: 3000,
               });
            }
         });
      },

      openRejectDialog(data) {

         const payLoad = {
            id : data.id,
            status : data.status
         }

         this.$confirm.require({
            message: 'Do you want to Reject this COO Order?',
            header: 'Reject Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
               this.updateMethod("customer-orders/reject", payLoad)
                  .then((data) => {
                     this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: data.message,
                        life: 3000,
                     });

                     this.index();
                  })
                  .catch((error) => {
                     this.loading = false;
                     this.$toast.add({
                        severity: "error",
                        summary: "Something Wrong",
                        detail: error.response.data.message,
                        life: 3000,
                     });
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Cancel Approve",
                  life: 3000,
               });
            }
         });
      },

   },
};
</script>

<style lang="scss">
.button-group {
   width: auto;
   margin: 2px;
}
</style>