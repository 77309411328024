<template>
   <div>
      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-d-flex p-jc-center" v-if="loading">
         <ProgressSpinner />
      </div>

      <div class="p-grid p-mt-4" v-if="!loading">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
                  <template v-slot:right>
                     <Dropdown v-model="selectedStatus" :options="statusLists" optionLabel="status" optionValue="field" placeholder="Select a Status" @change="onToggle" style="width: 20em"/>
                  </template>
               </Toolbar>
               <DataTable
                  ref="dt"
                  :value="lists"
                  :paginator="true"
                  :rows="10"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 25, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'productItemName',
                     'customerName',
                     'deliveryDate',
                     'deliveryTo',
                  ]"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column field="id" header="ID"></Column>
                  <Column field="productItemName" header="Product"></Column>
                  <Column field="customerName" header="Customer"></Column>
                  <Column field="quantity" header="Quantity"></Column>
                  <Column field="deliveryDate" header="Delivery Date"></Column>
                  <Column field="deliveryTo" header="Delivery To"></Column>
                  <Column field="status" header="Status"></Column>
                  <!-- <Column header="Order">
                  <template #body="{ data }">
                        <Button
                           label="Go to Order"
                           class="p-button-info mr-2 p-button-link"
                           @click="getOrderDetails(data.orderId)"
                           iconPos="right"
                        />
                     </template></Column> -->
                  <Column header="Action">
                     <template #body="{ data }">
                        <Button
                           label="Details"
                           icon="pi pi-eye"
                           class="p-button-rounded p-button-info mr-2"
                           @click="getDetails(data.id)"
                           iconPos="right"
                        />
                        <Button
                           :disabled="data.status != 1"
                           label="Approve"
                           icon="pi pi-check-circle"
                           class="p-button-rounded p-button-success mr-2 button-group"
                           @click="openApproveDialog(data)"
                           iconPos="right"
                        />

                        <Button
                           :disabled="data.status != 1"
                           label="Reject"
                           icon="pi pi-times-circle"
                           class="p-button-rounded p-button-danger mr-2 button-group"
                           @click="openRejectDialog(data)"
                           iconPos="right"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";

export default {
   mixins: [helperMixins, requestMixins],
   data() {
      return {
         items: [{ label: "Deliveries" }],
         statusLists: null,
			selectedStatus: null,
      };
   },
   created() {
      this.initFilters1();
      // this.indexFilterStatus(1);
      this.index();

      this.statusLists = [
         {field: 1, status: 'Delivered'},
         {field: 2, status: 'Received'},
         {field: 3, status: 'Canceled'},
         {field: 4, status: 'Approved'},
         {field: 5, status: 'Rejected'},
      ];
   },
   methods: {
      async index() {
         this.lists = (
				await this.getMethod('delivery')
			).data
			this.loading = false;
      },
      async indexFilterStatus(status) {
         
         this.lists = (
				await this.getMethodWithParams('delivery/all', "status", status)
			).data

			this.loading = false;
      },
      async onToggle(){
			this.indexFilterStatus(this.selectedStatus);
		},
      getDetails(id) {
         this.$router.push({ name: "deliveriesdetails", params: { id: id } });
      },

      getOrderDetails(order_id) {
         this.$router.push({ name: "cco_orders_details", params: { id: order_id } });
      },

      openApproveDialog(data) {

         const payLoad = {
            id : data.id,
            status : data.status
         }

         this.$confirm.require({
            message: 'Do you want to Approve this Delivery?',
            header: 'Approve Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
               this.updateMethod("delivery/approve", payLoad)
                  .then((data) => {
                     this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: data.message,
                        life: 3000,
                     });

                     this.index();
                  })
                  .catch((error) => {
                     this.loading = false;
                     this.$toast.add({
                        severity: "error",
                        summary: "Something Wrong",
                        detail: error.response.data.message,
                        life: 3000,
                     });
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Cancel Approve",
                  life: 3000,
               });
            }
         });
      },

      openRejectDialog(data) {

         const payLoad = {
            id : data.id,
            status : data.status
         }

         this.$confirm.require({
            message: 'Do you want to Reject this Delivery?',
            header: 'Reject Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
               this.updateMethod("delivery/reject", payLoad)
                  .then((data) => {
                     this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: data.message,
                        life: 3000,
                     });

                     this.index();
                  })
                  .catch((error) => {
                     this.loading = false;
                     this.$toast.add({
                        severity: "error",
                        summary: "Something Wrong",
                        detail: error.response.data.message,
                        life: 3000,
                     });
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Cancel Approve",
                  life: 3000,
               });
            }
         });
      },
   },
};
</script>