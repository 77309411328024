<template>
    <div>
        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>

         <div class="p-d-flex  p-jc-center p-mt-4" v-if="!loading">
            <div class="p-col-7 p-fluid card">
                <h4 class="p-text-center">Product Info</h4><hr>
                
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Serial Number</div>
                    <div class="p-col-4">{{delivery.serialNo}}</div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Delivery Date</div>
                    <div class="p-col-4">{{delivery.deliveryDate}}</div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Delivery To</div>
                    <div class="p-col-4">{{delivery.deliveryTo}}</div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Transpoter Name</div>
                    <div class="p-col-4">{{delivery.transporterTo}}</div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Transporter Contact</div>
                    <div class="p-col-4">{{delivery.transporterContact}}</div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Packing List Photo</div>
                    <div class="p-col-4">
                        <img :src="'https://hi5api-test.umgclouds.com/photo/'+delivery.itemPhoto" width="300" alt="Delivery Photo" />
                    </div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Delivery Photos</div>
                    <div class="p-col-4 p-d-flex">
                        <template v-for="(photo) in delivery.deliPhotoList" :key="photo">
                            <img v-if="photo.photoType === 1" :src="'https://hi5api-test.umgclouds.com/photo/' + photo.name" class="p-mr-2" width="100" />
                        </template>
                    </div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Receive Photos</div>
                    <div class="p-col-4">
                        <template v-for="(photo) in delivery.deliPhotoList" :key="photo">
                            <img v-if="photo.photoType === 2" :src="'https://hi5api-test.umgclouds.com/photo/' + photo.name" class="p-mr-2" width="100" />
                        </template>
                    </div>
                </div><hr>

                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Receive Date</div>
                    <div class="p-col-4">{{delivery.receiveDate}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Remark</div>
                    <div class="p-col-4">{{delivery.remark}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Status</div>
                    <div class="p-col-4">{{delivery.status}}</div>
                </div><hr>
            </div>
        </div>
    </div>
</template>

<script>
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";

export default {
    mixins: [helperMixins, requestMixins],
    data() {
		return {	
			delivery: "",
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Deliveries', to: '/deliveries'},
                {label: 'Details Deliveries'},
            ],
            loading: true,
		}
	},
    mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
            this.delivery = (await this.getMethod("delivery/detail/"+ this.$route.params.id)).data;
            // console.log(this.delivery)
            this.loading = false;
		}		
	},
}
</script>