<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-d-flex p-jc-center" v-if="loading">
         <ProgressSpinner />
      </div>

      <div class="p-grid p-mt-4" v-if="!loading">
         <div class="p-col-12 p-md-4 p-fluid">
            <div class="card">
               <h4 class="p-text-bold p-text-center">
                  {{ product_details.name }}
               </h4>
               <hr />
               <div class="p-fluid">
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Category Name</label>
                     <div class="p-ml-auto">
                        {{ product_details.name }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Stock</label>
                     <div class="p-ml-auto">
                        {{ product_details.stock }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Standard Price</label>
                     <div class="p-ml-auto">
                        {{ product_details.standardPrice }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold"
                        >Promotion Price</label
                     >
                     <div class="p-ml-auto">
                        {{ product_details.promotionPrice }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Minimum Price</label>
                     <div class="p-ml-auto">
                        {{ product_details.minimumPrice }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Currency</label>
                     <div class="p-ml-auto">
                        {{ product_details.currency }}
                     </div>
                  </div>
                  <hr />
                  <div class="p-field p-grid">
                     <label class="p-mr-auto p-text-bold">Remark</label>
                     <div class="p-ml-auto">
                        {{ product_details.description }}
                     </div>
                  </div>
                  <hr />
                  <h4 class="p-text-bold p-text-center">Extra Attributes</h4>
                  <hr />
                  <div
                     class="p-fluid"
                     v-for="(attribute, k) in product_details.attributeDTOList"
                     :key="k"
                  >
                     <div class="p-field p-grid">
                        <label class="p-mr-auto p-text-bold">{{
                           attribute.attributeName
                        }}</label>
                        <div class="p-ml-auto">
                           {{ attribute.value }}
                        </div>
                     </div>
                     <hr />
                  </div>
                  <Button
                     label="Update"
                     class="p-button-success p-mb-2"
                     @click="updateProductModal = true"
                  />
                  <Button
                     label="Delete"
                     class="p-button-danger"
                     @click="deleteProduct(product_details.id)"
                  />
               </div>

               <Dialog
                  header="Update Product Item"
                  v-model:visible="updateProductModal"
                  :style="{ width: '30vw' }"
                  :modal="true"
               >
                  <div class="p-fluid">
                     <div class="p-field">
                        <label for="type">Category Name</label>
                        <InputText
                           type="text"
                           placeholder="Category Name"
                           v-model="product_details.name"
                        />
                     </div>

                     <div class="p-field">
                        <label for="type">Standard Price</label>
                        <InputText
                           type="text"
                           placeholder="Standard Price"
                           v-model="product_details.standardPrice"
                        />
                     </div>

                     <div class="p-field">
                        <label for="type">Minimum Price</label>
                        <InputText
                           type="text"
                           placeholder="Minimum Price"
                           v-model="product_details.minimumPrice"
                        />
                     </div>

                     <div class="p-field">
                        <label for="type">Promotion Price</label>
                        <InputText
                           type="text"
                           placeholder="Promotion Price"
                           v-model="product_details.promotionPrice"
                        />
                     </div>

                     <div class="p-field">
                        <label for="description">Currency Unit</label>
                        <Dropdown
                           v-model="product_details.currency"
                           :options="currencies"
                           optionLabel="name"
                           optionValue="code"
                        />
                     </div>

                     <div class="p-field">
                        <label for="type">Description</label>
                        <InputText
                           type="text"
                           placeholder="Description"
                           v-model="product_details.description"
                        />
                     </div>

                     <label>Extra Attributes</label>

                     <div class="p-field">
                        <div
                           class="p-field p-mt-2"
                           v-for="attribute in product_details.attributeDTOList"
                           :key="attribute.attributeName"
                        >
                           <label for="name">{{
                              attribute.attributeName
                           }}</label>
                           <InputText type="text" v-model="attribute.value" />
                        </div>
                     </div>
                  </div>
                  <template #footer>
                     <Button
                        label="Cancel"
                        icon="pi pi-times"
                        @click="closeProductItem"
                        class="p-button-text"
                     />
                     <Button
                        label="Update"
                        icon="pi pi-check"
                        @click="updateProductItem"
                        autofocus
                        class="p-button-text"
                     />
                  </template>
               </Dialog>
            </div>
         </div>

         <div class="p-col-12 p-md-8 p-fluid">
            <div class="card">
               <h4 class="p-text-bold p-text-center">BU/Branch Stock</h4>
               <hr />
               <DataTable
                  :value="branches"
                  showGridlines
                  responsiveLayout="scroll"
               >
                  <Column field="abbreviation" header="Name"></Column>
                  <Column field="type" header="Type"></Column>
                  <Column field="stock" header="Stock"></Column>
               </DataTable>
            </div>

            <div class="card">
               <h4 class="p-text-bold p-text-center">Product Items</h4>
               <hr />
               <DataTable
                  :value="product_items"
                  showGridlines
                  responsiveLayout="scroll"
               >
                  <Column field="serialNo" header="Serial No"></Column>
                  <Column field="location" header="Location"></Column>
                  <Column field="arrivedDate" header="Arrived Date"></Column>
                  <Column
                     field="status"
                     header="status"
                     :style="{ width: '200px' }"
                  >
                     <template #body="{ data }">
                        <Badge
                           v-if="data.status == 1"
                           severity="success"
                           class="p-mr-1"
                           >Ready</Badge
                        >
                        <Badge v-else severity="danger" class="p-mr-1"
                           >Not Ready</Badge
                        >
                        <Button
                           icon="pi pi-refresh"
                           @click="changeStatus(data.id, data.status)"
                        />
                     </template>
                  </Column>
                  <Column header="Update Serial">
                     <template #body="{ data }">
                        <Button
                           label="Update"
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-warning mr-2"
                           iconPos="right"
                           @click="openSerialDialog(data)"
                        />
                     </template>
                  </Column>
                  <Column header="Transfer">
                     <template #body="{ data }">
                        <Button
                           label="Transfer"
                           icon="pi pi-external-link"
                           class="p-button-rounded p-button-success mr-2"
                           iconPos="right"
                           @click="openTransferModal(data.id)"
                        />
                     </template>
                  </Column>
                  <Column header="Check Transfer">
                     <template #body="{ data }">
                        <Button
                           label="Check"
                           icon="pi pi-search"
                           class="p-button-rounded p-button-primary mr-2"
                           iconPos="right"
                           @click="checkHistory(data.id)"
                        />
                     </template>
                  </Column>
                  <!-- <Column header="Delete">
                     <template #body="{ data }">
                        <Button
                           label="Delete"
                           icon="pi pi-trash"
                           class="p-button-rounded p-button-danger"
                           @click="deleteProductItem(data.id)"
                           iconPos="right"
                        />
                     </template>
                  </Column> -->
               </DataTable>

               <Dialog
                  header="Transfer To"
                  v-model:visible="transferItem"
                  :style="{ width: '30vw' }"
                  :modal="true"
               >
                  <div class="p-fluid">
                     <div class="p-field">
                        <label for="name">Name</label>
                        <Dropdown
                           v-model="transfer_product.location"
                           :options="business_sector_all"
                           optionLabel="name"
                           optionValue="id"
                           placeholder="--- Select One ---"
                        >
                           <template #option="slotProps">
                              <span>{{ slotProps.option.name }}</span> (<span>{{
                                 slotProps.option.abbreviation
                              }}</span
                              >)
                           </template>
                        </Dropdown>
                     </div>
                  </div>
                  <template #footer>
                     <Button
                        label="Cancel"
                        icon="pi pi-times"
                        @click="closeTransferModal"
                        class="p-button-text"
                     />
                     <Button
                        label="Submit"
                        icon="pi pi-check"
                        @click="transfer"
                        autofocus
                        class="p-button-text"
                     />
                  </template>
               </Dialog>

               <Dialog
                  header="Update Serial"
                  v-model:visible="serialDialog"
                  :style="{ width: '30vw' }"
                  :modal="true"
               >
                  <div class="p-fluid">
                     <div class="p-field">
                        <label for="name">Serial</label>
                        <InputText
                           @keyup.enter="updateSerial"
                           id="name"
                           type="text"
                           v-model="serial_no.serialNo"
                        />
                     </div>
                  </div>
                  <template #footer>
                     <Button
                        label="Cancel"
                        icon="pi pi-times"
                        @click="closeTransferModal"
                        class="p-button-text"
                     />
                     <Button
                        label="Submit"
                        icon="pi pi-check"
                        @click="updateSerial"
                        autofocus
                        class="p-button-text"
                     />
                  </template>
               </Dialog>
            </div>

            <div class="card">
               <h4 class="p-text-bold p-text-center">
                  Product Items Transfer History
               </h4>
               <hr />
               <DataTable
                  :value="product_items_transfer"
                  showGridlines
                  responsiveLayout="scroll"
               >
                  <template #empty> No History Found. </template>
                  <template #loading>
                     Loading Transfer History data. Please wait.
                  </template>
                  <Column field="serialNo" header="Serial No"></Column>
                  <Column field="sourceName" header="From"></Column>
                  <Column field="destinationName" header="To"></Column>
               </DataTable>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {
   changeNotReadyStatusService,
   changeReadyStatusService,
   deleteProductItemService,
   trasnsferProductItemService,
   updateProductService,
   deleteProductService,
} from "../../service/ProductService";
import { requestMixins } from "../../mixins/requestMixins";
import { helperMixins } from "../../mixins/helperMixins";

export default {
   mixins: [requestMixins, helperMixins],
   data() {
      return {
         items: [
            { label: "Product", to: "/products" },
            { label: "Details Product" },
         ],
         product_details: "",
         product_items: "",
         business_sector_all: "",
         branches: "",
         product_items_transfer: "",
         transferItem: false,
         updateProductModal: false,
         serialDialog: false,
         loading_transfer: false,
         transfer_product: {
            productItemId: "",
            location: "",
         },
         serial_no: {
            id: null,
            serialNo: null,
         },
         currencies: [
            { name: "MMK", code: "MMK" },
            { name: "USD", code: "USD" },
         ],
         token: this.$store.getters.getToken,
      };
   },
   created() {
      this.fetchBusinessAll();
      this.fetchBranches();
      this.fetchProductDetail();
      this.fetchProductItems();
      this.checkHistory();
   },
   methods: {
      async fetchProductDetail() {
         this.product_details = (
            await this.getMethod("product/" + this.$route.params.id)
         ).data;
         this.loading = false;
      },
      async fetchBusinessAll() {
         this.business_sector_all = (
            await this.getMethod("business-sector/all")
         ).data;
      },
      async fetchBranches() {
         this.branches = (
            await this.getMethod(
               "product/branchesWithStock/" + this.$route.params.id
            )
         ).data;
      },
      async fetchProductItems() {
         this.product_items = (
            await this.getMethod(
               "product/item/byProductId/" + this.$route.params.id
            )
         ).data;
      },
      changeStatus(id, status) {
         if (status == 0) {
            this.$confirm.require({
               message: "Are you sure you want to mark this item as Ready?",
               header: "Confirmation",
               icon: "pi pi-exclamation-triangle",
               accept: () => {
                  (this.loading = true),
                     changeReadyStatusService(id, this.token).then(
                        (data) => {
                           this.$toast.add({
                              severity: "success",
                              summary: "Success",
                              detail: data.message,
                              life: 3000,
                           }),
                              this.fetchProductItems();
                        },
                        (error) => {
                           console.log(error);
                        }
                     );
               },
               reject: () => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Rejected",
                     detail: "You have rejected",
                     life: 3000,
                  });
               },
            });
         } else {
            this.$confirm.require({
               message: "Are you sure you want to mark this item as Not Ready?",
               header: "Confirmation",
               icon: "pi pi-exclamation-triangle",
               accept: () => {
                  (this.loading = true),
                     changeNotReadyStatusService(id, this.token).then(
                        (data) => {
                           this.$toast.add({
                              severity: "success",
                              summary: "Success",
                              detail: data.message,
                              life: 3000,
                           }),
                              this.fetchProductItems();
                        },
                        (error) => {
                           console.log(error);
                        }
                     );
               },
               reject: () => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Rejected",
                     detail: "You have rejected",
                     life: 3000,
                  });
               },
            });
         }
      },
      openTransferModal(productItemId) {
         this.transferItem = true;

         this.transfer_product.productItemId = productItemId;
      },
      closeTransferModal() {
         this.transferItem = false;

         this.transfer_product.productItemId = "";
         this.transfer_product.location = "";
      },
      transfer() {
         (this.loading = true),
            trasnsferProductItemService(this.transfer_product, this.token).then(
               (data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  }),
                     this.closeTransferModal();
                  this.fetchProductItems();
                  this.fetchBranches();
                  this.loading = false;
               },
               (error) => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.message,
                     life: 3000,
                  });
               }
            );
      },
      updateProductItem() {
         (this.loading = true),
            updateProductService(this.token, this.product_details).then(
               (data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  }),
                     this.fetchProductDetail();
                  this.updateProductModal = false;
                  this.loading = false;
               },
               (error) => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.message,
                     life: 3000,
                  });
               }
            );
      },
      deleteProduct(id) {
         this.$confirm.require({
            message: "Are you sure you want to Procced?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               (this.loading = true),
                  deleteProductService(id, this.token).then(
                     (data) => {
                        this.$toast.add({
                           severity: "success",
                           summary: "Success",
                           detail: data.message,
                           life: 3000,
                        }),
                           this.$router.push({ name: "product_lists" });
                     },
                     (error) => {
                        this.$toast.add({
                           severity: "error",
                           summary: "Something Wrong",
                           detail: error.message,
                           life: 3000,
                        });
                     }
                  );
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
      deleteProductItem(id) {
         this.$confirm.require({
            message: "Are you sure you want to Procced?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               (this.loading = true),
                  deleteProductItemService(id, this.token).then(
                     (data) => {
                        this.$toast.add({
                           severity: "success",
                           summary: "Success",
                           detail: data.message,
                           life: 3000,
                        }),
                           this.fetchProductItems();
                        this.fetchBranches();
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
      openSerialDialog(data) {
         this.serial_no.id = data.id;
         this.serial_no.serialNo = data.serialNo;

         this.serialDialog = true;
      },
      updateSerial() {
         this.loading = true;
         this.updateMethod("product/item/serialNo", this.serial_no)
            .then((data) => {
               this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: data.message,
                  life: 3000,
               });
               this.serialDialog = false;
               this.loading = false;
               this.fetchProductItems();
            })
            .catch((error) => {
               this.serialDialog = false;
               this.loading = false;
               this.$toast.add({
                  severity: "error",
                  summary: "Something Wrong",
                  detail: error.response.data.errors[0],
                  life: 3000,
               });
            });
      },
      async checkHistory() {
         this.loading_transfer = true;
         this.product_items_transfer = (
            await this.getMethod("product/item/transferHistory/" + this.$route.params.id)
         ).data;
         this.loading_transfer = false;
      },
      add() {
         this.add_product.serialNumbers.push("");
      },
      remove(index) {
         this.add_product.serialNumbers.splice(index, 1);
      },
   },
};
</script>